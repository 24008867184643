/**
 * NAVDROP styles
 * by SalsaLab
 */


$_navdrop-breakpoint     : 'md' !default;

$_navdrop-bg             : $_button-bg !default;
$_navdrop-bg-hover       : $_button-bg-hover !default;

$_navdrop-color          : $_button-color !default;
$_navdrop-color-hover    : $_button-color-hover !default;

$_navdrop-padding        : $_button-padding !default;
$_navdrop-border-radius  : $_button-border-radius !default;

$_navdrop-z-index        : 5 !default;
$_navdrop-content-bg     : $body-bg !default;
$_navdrop-content-shadow : 0 0.2rem 0.4rem transparentize($black, 0.25) !default;




.navdrop-drop {
	background: $_navdrop-bg; border-radius: $_navdrop-border-radius; color: $_navdrop-color; cursor: pointer; padding: $_navdrop-padding; position: relative; z-index: $_navdrop-z-index + 1;
	@media all and (min-width: map-get($grid-breakpoints, $_navdrop-breakpoint)) { display: none; }
}

.navdrop-title { display: block; overflow: hidden; text-align: center; text-overflow: ellipsis; }

.navdrop-closed,
.navdrop-opened { position: absolute; right: 0.4rem; top: 50%; transform: translateY(-50%); }

.navdrop-closed {}
.navdrop-opened { display: none; }

.navdrop-content {
	//
	@media all and (max-width: map-get($grid-breakpoints, $_navdrop-breakpoint) - 1) { background: $_navdrop-content-bg; box-shadow: $_navdrop-content-shadow; opacity: 0; pointer-events: none; position: absolute; transition: $transition-fade; width: 100%; }
}

.navdrop {
	position: relative; z-index: $_navdrop-z-index;

	&:hover,
	&:active {
		.navdrop-content {
			@media all and (max-width: map-get($grid-breakpoints, $_navdrop-breakpoint) - 1) { opacity: 1; pointer-events: all; }
		}

		.navdrop-drop { border-radius: $_navdrop-border-radius $_navdrop-border-radius 0 0; }
		.navdrop-closed { display: none; }
		.navdrop-opened { display: block; }
	}
}
