/**
 * SCSS ICONFONT Styles
 * by SalsaLab
 */


@if $_enable-iconfont {

	// vars
	$_ico-size-sm : 1.6rem !default;
	$_ico-size    : 2.4rem !default;
	$_ico-size-md : 4.8rem !default;
	$_ico-size-lg : 6.4rem !default;

	$_ico-area-sm : $_ico-size-sm !default;
	$_ico-area    : $_ico-size / 2 !default;
	$_ico-area-md : $_ico-size-md / 2 !default;
	$_ico-area-lg : $_ico-size-lg / 2 !default;

	$_enable-ico-standalone : false !default;




	@mixin ico() { display: inline-block; font-family: $_font-iconset; font-size: $_ico-size; font-weight: normal; line-height: 1; vertical-align: middle; }


	.ico {
		display: inline-block; line-height: 1;

		&::before { @include ico; color: inherit; content: attr(data-ico); padding: $_ico-area; }
	}

	.ico-sm::before { font-size: $_ico-size-sm; padding: $_ico-area-sm; }
	.ico-md::before { font-size: $_ico-size-md; padding: $_ico-area-md; }
	.ico-lg::before { font-size: $_ico-size-lg; padding: $_ico-area-lg; }

	.ico-inline::before { padding: 0 0.8rem 0 0; }


	@if $_enable-ico-standalone
	{
		.ico-standalone {
			@include hide-text; height: $_ico-size + ($_ico-area * 2); position: relative; width: $_ico-size + ($_ico-area * 2);
			&::before { left: 0; position: absolute; text-indent: 0; top: 0; }
		}
	}
}
