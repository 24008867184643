/*
 * SCSS BASIC: INLINE Tags file
 * by SalsaLab
 */




// Links
// **************************************************

a {
	color: $link-color; cursor: pointer; text-decoration: $link-decoration; transition: $transition-base;

	&:active,
	&:hover { color: $link-hover-color; outline: 0; text-decoration: $link-hover-decoration; }


	@if $_enable-visited-links {

		$link-visited-color      : tint($link-color, 25%) !default;
		$link-visited-decoration : inherit;


		&:visited { color: $link-visited-color; text-decoration: $link-visited-decoration; }
	}
}





// Reference
// **************************************************

@if $_enable-inline-quotes {

	$_quote-preix: '\201D' !default;
	$_quote-sufix: '\201C' !default;




	q {
		font-style: italic;
		&::after { content: $_quote-preix; }
		&::before { content: $_quote-sufix; }
	}
}





// Reference
// **************************************************

@if $_enable-sub-sup-index {

	$_sup-sup-index-size: 75% !default;
	$_sub-offset        : -0.5em !default;
	$_sup-offset        : -0.75em !default;
	%sup-sub { font-size: $_sup-sup-index-size; line-height: 0; position: relative; vertical-align: baseline; }




	sub,
	sup { @extend %sup-sub; }

	sub { bottom: $_sub-offset; }
	sup { top: $_sup-offset; }
}
