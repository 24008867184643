@font-face {
    font-family: "Lovelo";
    src: url("#{$_font-path}/Lovelo_Black.eot");
    src: url("#{$_font-path}/Lovelo_Black.eot?#iefix") format("embedded-opentype"),
         url("#{$_font-path}/Lovelo_Black.woff2") format("woff2"),
         url("#{$_font-path}/Lovelo_Black.woff") format("woff"),
         url("#{$_font-path}/Lovelo_Black.ttf") format("ttf"),
         url("#{$_font-path}/Lovelo_Black.svg#Lovelo") format("svg");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Lato-Black";
    src: url("#{$_font-path}/Lato-Black.eot");
    src: url("#{$_font-path}/Lato-Black.eot?#iefix") format("embedded-opentype"),
         url("#{$_font-path}/Lato-Black.woff2") format("woff2"),
         url("#{$_font-path}/Lato-Black.woff") format("woff"),
         url("#{$_font-path}/Lato-Black.ttf") format("ttf"),
         url("#{$_font-path}/Lato-Black.svg#Lovelo") format("svg");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Lato-Bold";
    src: url("#{$_font-path}/Lato-Bold.eot");
    src: url("#{$_font-path}/Lato-Bold.eot?#iefix") format("embedded-opentype"),
         url("#{$_font-path}/Lato-Bold.woff2") format("woff2"),
         url("#{$_font-path}/Lato-Bold.woff") format("woff"),
         url("#{$_font-path}/Lato-Bold.ttf") format("ttf"),
         url("#{$_font-path}/Lato-Bold.svg#Lovelo") format("svg");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Lato-Light";
    src: url("#{$_font-path}/Lato-Light.eot");
    src: url("#{$_font-path}/Lato-Light.eot?#iefix") format("embedded-opentype"),
         url("#{$_font-path}/Lato-Light.woff2") format("woff2"),
         url("#{$_font-path}/Lato-Light.woff") format("woff"),
         url("#{$_font-path}/Lato-Light.ttf") format("ttf"),
         url("#{$_font-path}/Lato-Light.svg#Lovelo") format("svg");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Lato";
    src: url("#{$_font-path}/Lato-Regular.eot");
    src: url("#{$_font-path}/Lato-Regular.eot?#iefix") format("embedded-opentype"),
         url("#{$_font-path}/Lato-Regular.woff2") format("woff2"),
         url("#{$_font-path}/Lato-Regular.woff") format("woff"),
         url("#{$_font-path}/Lato-Regular.ttf") format("ttf"),
         url("#{$_font-path}/Lato-Regular.svg#Lovelo") format("svg");
    font-style: normal;
    font-weight: 400;
}