/*
 * SCSS THEME BASIC TAGS File
 */

// BLOCK ELEMENTS
// **************************************************

.main-title,
h1 {
	font-size: 2.8rem;
	@media all and (min-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint)) { font-size: $font-size-h1; line-height: 4.8rem; }
	//span { background: transparentize($gray-darker, 0.2); padding-top: 0.4rem; }
}

h2 {
	font-size: 2.2rem;
	@media all and (min-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint)) { font-size: $font-size-h2; }
}

h1,
h2,
h3,
h4,
h5 {
	color: $brand-secondary; font-weight: normal; line-height: 1;
	&:first-child { color: $white; margin-bottom: 0; margin-top: 0; }
	//&:first-child { text-transform: uppercase; }
}

h1,
h2,
h3,
h4,
h5,
p,
li {
	font-family: 'Lato-Light';

	.highlight { color: $brand-primary; }
	strong { font-family: 'Lato-Bold'; }
}

a:active,
a:hover { color: $brand-primary; }



// INLINE ELEMENTS
// **************************************************

// -- OVERRIDES HERE!!!




// FORMS
// **************************************************

input:not([type="radio"]):not([type="checkbox"]),
select,
textarea {
	+ label { font-family: $headings-font-family; font-size: 1.2rem; }
}

input:placeholder-shown:not([type="radio"]):not([type="checkbox"]),
select:invalid,
textarea:placeholder-shown {
	&:not(:focus) + label { font-size: 1.2rem; }
}

button,
.button,
.button-outline
 { font-family: $headings-font-family; font-size: 1.4rem; line-height: 1; padding: 1rem 1.6em; text-transform: uppercase; }

.button-outline {
	background: transparent;
	border: 2px solid $white;

	&:hover,
	&:focus,
	&:active { background: transparent; }

	&.inverse { border-color: $brand-accent; color: $brand-darker; }
}
