/*
 * SCSS THEME LAYOUT File
 */



// COMMON
// **************************************************

// -- OVERRIDES HERE!!!

html,
body {
	height: 100%;
}

body>* {
	font-family: 'Lato';
	@media all and (min-width: map-get($grid-breakpoints, 'lg')) { font-size: 1.5rem; }
}

button,
.button { margin-bottom: 0; }

em { font-style: normal; }

// HEADER
// **************************************************

.header {
	background: $white;
	//@media all and (min-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint)) { background: transparentize($black, 0.2); }

}

/*
.content {
	@media all and (min-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint)) { margin-top: 0; }
}
*/

.site-logo {
	@media all and (max-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint) - 1) { order: 1; }
}

.main-menu {
	transition: $transition-base;
	@media all and (max-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint) - 1) { flex: 0 0; order: 2; }
	@media all and (min-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint)) { flex: 1 1; }

	a {
		font-family: $headings-font-family; font-size: 1.4rem; line-height: 1.6rem;

		@media all and (min-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint))
		{
			color: $brand-accent; height: 9rem; padding: 3.7rem 1.6rem 0;

			&.active { color: $brand-primary; }
			&:hover,
			&:active { color: $brand-primary; transform: scale(90%); }
		}
	}
}

.menu-content {
	justify-content: flex-end;

	@media all and (max-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint) - 1) {
		background: $white; box-shadow: 0 0.2rem 0.4rem transparentize($black, 0.5), 0 0.2rem 1rem transparentize($black, 0.2); padding: 0.8rem; top: 6.3rem;
	}

	@media all and (min-width: map-get($grid-breakpoints, 'md')) {

	}

	a {
		text-align: center;
		text-transform: uppercase;
		@media all and (max-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint) - 1) { color: $brand-darker; font-size: 1.2rem; padding: 0.8rem; }
	}


}

.children {
	//background-color: $brand-primary; padding: 0.8rem;
	width: 15rem;
	@media all and (max-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint) - 1) {
		//padding-bottom: 1.6rem;
		width: 100%;
	}

	a {
		font-family: $font-family-base; height: auto; text-transform: initial; transition: initial;
		&:hover { color: $white; }

		@media all and (min-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint)) {
			color: $brand-primary; font-size: 1.3rem; padding: 1.3rem 1.8rem; text-align: left;
		}
	}

	.mnu {
		//
		&:hover { background-color: $brand-primary; }
		&:last-child a {
			border-bottom: 0;
		}

		@media all and (max-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint) - 1) {
			background-color: $gray-lightest-2;
		}
	}
}



// MOBILE: Dropdown Menu
// **************************************************


@if $_menu-mobile-type == dropdown {

	@media all and (max-width: map-get($grid-breakpoints, $_menu-mobile-breakpoint) - 1px) {

		.menu { position: static; }
	}
}


// FOOTER
// **************************************************

.footer {
	color: $brand-accent;
	font-family: 'Lato-Light';
	font-size: 1.4rem;
	padding: 3.2rem 0;
	position: relative;
	text-align: center;

	.col-md-4 {
		padding-bottom: 3.2rem;
		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { text-align: center; }
	}

	&::after {
		border-top: 2px solid $gray-lightest;
		content: '';
		left: 0;
		margin: 0 auto;
		max-width: 45%;
		position: absolute;
		right: 0;
		top: 0;
	}
}

.footer-logo-link { margin: 0 auto; }
.footer-logo { margin: 0 auto; }
.footer-mnu {
	padding: 1.6rem 0;
}

.footer-sm {
	//
	a {
		color: $gray-lightest;

		&:hover { color: $brand-primary; }
		&:first-of-type {
			@media all and (min-width: map-get($grid-breakpoints, 'md')) { margin-left: -1.2rem; }
		}

		&::before { }
	}
}


// COMPONENTS
// **************************************************

// SWIPER

//Arrows fix
.swiper-button-prev,
.swiper-button-next {
	cursor: pointer; position: absolute; top: 50%; transform: translateY(-50%); z-index: 10;
	//background: transparentize($brand-darker, 0.5);
	&::before {
		padding: 0;
		@media all and (min-width: map-get($grid-breakpoints, 'md')) { font-size: 10rem; }
	}
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled { cursor: default; opacity: 0.35; pointer-events: none; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next { left: 0; right: auto; }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev { left: auto; right: 0; }



.swiper-wrapper .swiper-slide {
	//
	&::after {
		@media all and (min-width: map-get($grid-breakpoints, 'md')) { background-color: $white;bottom: 0;content: '';height: 13vh;position: absolute;width: 100%;z-index: 2; }
	}
}

.swiper-pagination-bullet { background-color: $gray; border: 0; height: 1.3rem; width: 1.3rem; }
.swiper-pagination-bullet-active { background-color: $brand-primary; }

/*
.swiper-button-prev {
	border-radius: 0 5.6rem 5.6rem 0;
	&::before { padding-left: 0; }
}

.swiper-button-next {
	border-radius: 5.6rem 0 0 5.6rem;
	&::before { padding-right: 0; }
}
*/

// Main Slider Height
.page-header .top {
	.swiper-wrapper,
	.swiper-slide {
		min-height: 44rem;
		@media all and (min-width: map-get($grid-breakpoints, 'md')) { height: 85vh; }

	}
}


.top .swiper-slide {
	//

	.container {
		padding-bottom: 4rem; padding-top: 4rem;
		@media all and (min-width: map-get($grid-breakpoints, 'md')) { bottom: 0%; left: 0; position: absolute; right: 0; }
	}

	h4 { color: $brand-primary; }

	p {
		color: $white;
		font-size: 3.5rem;
		line-height: 1.3;
		margin: 0;
		text-transform: uppercase;
	}

	.slider-media {
		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { display: none; }
		margin-bottom: -2rem;
		text-align: right;
	}

	.slider-content { margin-bottom: 9rem; margin-top: auto; }

	.banner {
		margin-bottom: auto;
		min-width: 101%;
		//@media all and (min-width: map-get($grid-breakpoints, 'xl')) {top: 60%;}
	}

}

// HOME BANNER

.home-top-banner {
	background-image: url(#{$_image-path}/bkg/home-top-banner@2x.jpg);
	background-size: cover;
	color: $white;
	font-weight: 200;
	//min-height: calc(100vh - 9rem);
	min-height: 100vh;
	padding: 0;
	position: relative;
	text-align: center;

	@media all and (min-width: map-get($grid-breakpoints, 'md')) { margin-top: -9rem; }
	//@media (min-width: map-get($grid-breakpoints, 'md')) and (-webkit-min-device-pixel-ratio: 2), (min-width: map-get($grid-breakpoints, 'md')) and (min-resolution: 192dpi) { background-image: url(#{$_image-path}/bkg/home-top-banner@2x.jpg); }
	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { margin-top: -6.4rem; }

	>.container-fluid {
		@media all and (min-width: map-get($grid-breakpoints, 'md')) { bottom: 4%; left: 0; position: absolute; right: 0; }
		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { padding-bottom: 4rem; padding-top: 9rem; }

		>.justify-content-center {
			@media all and (min-width: map-get($grid-breakpoints, 'md')) { padding: 0 12rem; }
			@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { margin: 0 8px; }
			animation: fade-rise 1.8s;
			position: relative;
			z-index: 1;

			&::before {
				background-color: $brand-primary;
				bottom: 10%;
				content: '';
				height: 38%;
				position: absolute;
				width: 78%;
				z-index: -1;

				@media all and (min-width: map-get($grid-breakpoints, 'lg')) {
					bottom: auto;
					height: 123%;
					right: 13%;
					top: -11%;
					width: 32.3%;
				}

				@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
					//bottom: 9%;
					content: none;
					//height: 44%;
					//width: 102%;
				}
			}
		}
	}

	.button { animation: fade-rise 1.8s; animation-delay: 0.2s; margin-bottom: 2rem; margin-top: 4rem;}

	//>.container-fluid { position: static; }
}

.banner-container {
	border: 2px solid $white;
	padding-bottom: 2.5rem;
	padding-top: 2.5rem;
	position: relative;
	width: 100%;

	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
		background-color: transparentize($black, 0.3);
	}

	&::after {
		background-color: transparentize($black, 0.3);
		content: '';
		height: 56%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: -1;
		@media all and (min-width: map-get($grid-breakpoints, 'lg')) {
			height: 100%;
			width: 57.5%;
		}
		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) {
			content: none;
		}
	}

	@media all and (min-width: map-get($grid-breakpoints, 'lg')) {
		&::before {

			border-left: 2px solid $white;
			content: '';
			height: 87%;
			left: 58.5%;
			position: absolute;
			top: 6%;
		}
	}

	h1:first-child {
		align-self: center;
		line-height: 1.3;
		@media all and (min-width: map-get($grid-breakpoints, 'md')) { font-size: 4.3rem; }
		@media all and (min-width: map-get($grid-breakpoints, 'xl')) { font-size: 5.8rem; }
	}

	.banner-heading {
		display: flex;
		position: relative;
		@media all and (min-width: map-get($grid-breakpoints, 'lg')) { text-align: right; }

	}

	.banner-content {
		align-self: center;
		@media all and (min-width: map-get($grid-breakpoints, 'lg')) { font-size: 1.7rem; margin-left: 2.5%; text-align: left; }

		p {
			margin-top: 1.5em;
			position: relative;
			z-index: 1;

			@media all and (min-width: map-get($grid-breakpoints, 'lg')) { margin-bottom: 0; margin-top: 0; padding: 0 6%; }
		}
	}

}

// MOUSE ANIMATED ICON

.mouse-icon {
	border: 2px solid $white;
	border-radius: 16px;
	display: block;
	height: 35px;
	margin: 0 auto;
	opacity: 0.7;
	width: 20px;
	z-index: 10;

	.wheel {
		animation-delay: 0s;
		animation-duration: 1s;
		animation-iteration-count: infinite;
		animation-name: drop;
		animation-play-state: running;
		animation-timing-function: linear;
		background: $white;
		border-radius: 10px;
		height: 4px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		top: 4px;
		width: 2px;
	}
}

@keyframes fade-rise {
	0% {
		opacity: 0;
		transform: translateY(-30px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}

}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.fadein {
	animation-name: fadeIn;
}

@keyframes drop {
	0% {
		opacity: 0;
		top: 5px;
	}

	30% {
		opacity: 1;
		top: 10px;
	}

	100% {
		opacity: 0;
		top: 25px;
	}

}

// SWIPER CAROUSEL
.carousel {
	position: relative;
	text-align: center;
	text-transform: uppercase;
	.swiper-slide .banner { width: 100%; }
	.swiper-container { position: relative; width: 80%; }

	.swiper-button-next,
	.swiper-button-prev {
		bottom: -1rem;
		top: auto;
		@media all and (min-width: map-get($grid-breakpoints, 'md') - 1) { bottom: -5rem; }
	}

	.swiper-button-next { right: 0; }
	.swiper-button-prev { left: 0; }
}

.product .swiper-slide,
.entry .swiper-slide {
	text-align: center;
}


// Breadcrumbs
.breadcrumbs {
	color: $white;
	padding-top: 3.2rem;
}

// Tabs
.tab-list {
	//

	input {
		display: none;

		&[type="radio"] + label { color: $white; font-size: 1.6rem; }
		&:checked + label {
			background-color: $white;
			background-image: url(#{$_image-path}/icons/tab-hover-icon.svg);
			color: $gray;
		}
	}

	label {
		background-color: $gray;
		background-image: url(#{$_image-path}/icons/tab-icon.svg);
		background-position: right center;
		background-repeat: no-repeat;
		color: $white;
		display: inline-block;
		float: left;
		font-weight: 600;
		padding: 1.5rem 8rem;
		padding-left: 3rem;
		text-align: left;

		&:nth-of-type(3) { background-color: $brand-darker; }
		&:hover { cursor: pointer; }

	}

}

//#tab3:checked .icn-1 { background-color: $brand-darker; }

.tab-content {
	background-color: $gray-lightest-2;
	color: $brand-darker;
	height: 100%;
	min-height: 55vh;

	.tab-panel {
		display: none;
		padding: 5rem 3rem 2rem;

		h2 { color: $brand-darker; font-family: $font-family-sans-serif; font-weight: normal; margin: 0; text-transform: uppercase; }
		h3 { color: $brand-darker; margin: 10px 0; }
	}
}


// COMMON FORM Styles

input:not([type=radio]):not([type=checkbox]),
select,
textarea {
	border-bottom: 1px solid $gray;
	color: $brand-primary;

	// placeholder
	&::placeholder { color: $gray; }
}

.contact-title {
	padding: 0;
	padding-bottom: 4rem;

	h3 { font-family: 'Lato'; text-align: center; text-transform: uppercase; }
	h2 {
		margin: 0; text-align: center; text-transform: uppercase;
		span { color: $brand-primary; }
	}

	//p { margin-top: 0; text-align: center; }
}

.sectitle {
	color: $brand-primary; font-family: $headings-font-family; font-size: 1.8rem; padding-bottom: 1.6rem; padding-top: 6.4rem; position: relative;
	&::before { background: $white; border-radius: 100%; color: $brand-accent; content: counter(formstep); counter-increment: formstep; display: inline-block; height: 3.2rem; line-height: 3.2rem; margin-right: 1.6rem; text-align: center; vertical-align: bottom; width: 3.2rem; }
}

.contact-container { counter-reset: formstep; margin: 0 auto; max-width: (map-get($container-max-widths, 'xl') / 2) + 16px; padding: 0 0.8rem; }
.buttons { margin: 0 auto; padding: 1.6rem 0.8rem; }


//PAGE SECTION (WHITE BG)

.page-section {
	padding: 4rem 0;

	h1,
	h2 {
		color: $black;
		margin-top: 2rem;
		text-align: center;
		text-transform: uppercase;

		&::after {
			border-top: 2px solid $brand-primary;
			content: '';
			display: block;
			margin: 10px auto 0;
			width: 7rem;
			@media all and (min-width: map-get($grid-breakpoints, 'md')) { width: 17rem; }

		}
	}

	h3 { color: $brand-accent; text-align: center; text-transform: uppercase; }

	.heading-ganar {
		h2 { color: $gray; }
		strong { color: $brand-primary; }
	}
}



//BANNERS


.top-banner-bg,
.middle-banner,
.top-banner,
.parallax-banner {
	background-position: center;
	background-size: cover;
	color: $white;
	text-align: center;
}

//TOP BANNER BG

.top-banner-bg {
	height: 40%;
	position: absolute;
	width: 100%;

	@media all and (min-width: map-get($grid-breakpoints, 'md')) {
		height: 50%;
		min-height: 400px;
	}
}

//TOP BANNER

.top-banner {
	padding: 4rem 0;

	&.page-top-quienes-somos {
		@media all and (min-width: map-get($grid-breakpoints, 'md')) { padding-bottom: 7rem; }
	}

	@media all and (min-width: map-get($grid-breakpoints, 'md')) { padding: 15rem 0; }
}



//MIDDLE BANNER

.middle-banner {
	padding: 8.8rem 0;
	position: relative;
	text-align: left;
	>div { position: relative; z-index: 2; }

/*
	&::after {
		background-color: transparentize($brand-secondary, 0.2);
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 50%;
		z-index: 1;
	}
*/
}

//PARALAX BANNER

.parallax-banner {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	padding: 4.8rem 0;

	p:first-of-type {
		@media all and (min-width: map-get($grid-breakpoints, 'md')) {width: 70%; }
	}

	.button { margin-top: 2rem; }

	h1:first-child {
		margin-bottom: 4rem;
		@media all and (min-width: map-get($grid-breakpoints, 'md')) { font-size: 4.3rem; }
		@media all and (min-width: map-get($grid-breakpoints, 'lg')) { font-size: 6rem; }
	}

	h2 {
		color: $white;
		display: inline-block;
		position: relative;
		vertical-align: middle;
		width: 33%;
		@media all and (min-width: map-get($grid-breakpoints, 'md')) { font-size: 1.8rem; }
		@media all and (min-width: map-get($grid-breakpoints, 'lg')) { font-size: 2.5rem; }
		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { margin: 3rem 0; width: 38%; }
		@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) { width: 100%; }

		&::after,
		&::before {
			border: 2px solid $brand-primary;
			content: '';
			height: 30px;
			position: absolute;
			width: 30px;
		}

		&::before {
			border-width: 2px 0 0 2px;
			left: 5px;
			top: -15px;
		}

		&::after {
			border-width: 0 2px 2px 0;
			bottom: -15px;
			right: 5px;
		}

		span { color: $white; font-family: $font-family-sans-serif; font-weight: normal; text-transform: uppercase; }
	}
}


// FULL BKG COLOR

.page-section-blue,
.page-section-black {
	padding: 5rem 0;
	text-align: center;

	h2 {
		color: $white; font-family: $font-family-sans-serif; font-weight: lighter; margin-bottom: 0; text-transform: uppercase;
	}
}

//Blue Banner
.page-section-blue {
	background-color: $brand-primary;
}


//Black Banner
.page-section-black {
	background-color: $black;
	p { font-size: 2rem; }

	h2 {
		margin-top: 0;
		&::after { content: none; }
	}

	.contact-banner-mail p { background: url(#{$_image-path}/icons/ico-contact-banner-1.svg) no-repeat -4px center; }
	.contact-banner-tel p { background: url(#{$_image-path}/icons/ico-contact-banner-2.svg) no-repeat -4px center; }
}

.sec-soporte {
	.page-section-black {
		p { margin-top: 3rem; padding: 2.5rem 0 2.5rem 8rem; text-align: left; }
	}
}


//Banners background

.page-parallax-mejor-concepto { background-image: url(#{$_image-path}/bkg/parallax-mejor-concepto.jpg); }
.home-mision { background-image: url(#{$_image-path}/bkg/home-mision.jpg); }

.page-top-mision-vision {
	background-image: url(#{$_image-path}/bkg/mision-vision-top-banner.jpg);
	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { padding: 9rem 0; };
}

.page-top-nuestra-historia { background-image: url(#{$_image-path}/bkg/nuestra-historia-top-banner.jpg); }

.interior-noticia {
	background-image: url(#{$_image-path}/bkg/interior-noticia-top-banner.jpg);
	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { padding: 9rem 0; };
}

.entry-page-top { background-image: url(#{$_image-path}/bkg/entry-top-banner.jpg); }

.contacto-page-top {
	background-image: url(#{$_image-path}/bkg/contacto-top-banner.jpg);
	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { padding: 9rem 0; };
}

.alianzas-page-top {
	background-image: url(#{$_image-path}/bkg/alianzas-top-banner.jpg);
	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { padding: 9rem 0; };
}

.fumed-top-banner { background-image: url(#{$_image-path}/bkg/fumed-top-banner.jpg); }
.marcas-page-top {
	background-image: url(#{$_image-path}/bkg/marcas-top-banner.jpg);
	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { padding: 9rem 0; };
}


@media (min-width: map-get($grid-breakpoints, 'md')) and (-webkit-min-device-pixel-ratio: 2), (min-width: map-get($grid-breakpoints, 'md')) and (min-resolution: 192dpi)
{

	.page-parallax-mejor-concepto { background-image: url(#{$_image-path}/bkg/parallax-mejor-concepto@2x.jpg); }
	.home-mision { background-image: url(#{$_image-path}/bkg/home-mision@2x.jpg); }

	.page-top-quienes-somos { background-image: url(#{$_image-path}/bkg/quienes-somos-top-banner@2x.jpg); }
	.page-top-mision-vision { background-image: url(#{$_image-path}/bkg/mision-vision-top-banner@2x.jpg); }
	.page-top-nuestra-historia { background-image: url(#{$_image-path}/bkg/nuestra-historia-top-banner@2x.jpg); }
	.interior-noticia { background-image: url(#{$_image-path}/bkg/interior-noticia-top-banner@2x.jpg); }
	.entry-page-top { background-image: url(#{$_image-path}/bkg/entry-top-banner@2x.jpg); }
	.contacto-top { background-image: url(#{$_image-path}/bkg/contacto-top-banner@2x.jpg); }
	.alianzas-page-top { background-image: url(#{$_image-path}/bkg/alianzas-top-banner@2x.jpg); }
	.fumed-top-banner { background-image: url(#{$_image-path}/bkg/fumed-top-banner@2x.jpg); }
	.marcas-page-top { background-image: url(#{$_image-path}/bkg/marcas-top-banner@2x.jpg); }

}


// SECTIONS
// **************************************************


.border-top {
	position: relative;

	&::after {
		border-top: 2px solid $gray-lightest;
		content: '';
		left: 0;
		margin: 0 auto;
		max-width: 45%;
		position: absolute;
		right: 0;
		top: 0;
	}
}

// HOME

.be-home {
	text-align: center;

	h1,
	h2,
	h3,
	h4 { text-transform: uppercase; }
}

.heading-ganar {
	h2:first-child {
		line-height: 1.4;
		position: relative;
		text-transform: uppercase;
		&::before { border: 1px solid $brand-primary; content: ''; height: 19rem; left: -2rem; position: absolute; }
	}
}

// Featured News

.entry-recomendations {
	//
	.featured-image {
		@media all and (min-width: map-get($grid-breakpoints, 'sm')) {
			max-width: 64%;
		}
	}

	.featured-content {
		background-color: $white;
		padding: 2rem;

		@media all and (min-width: map-get($grid-breakpoints, 'sm')) {
			border-left: 3px solid $brand-primary;
			margin-top: 6%;
			max-width: 50%;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}


//QUIENES SOMOS

.page-top-quienes-somos {
	background-image: url(#{$_image-path}/bkg/quienes-somos-top-banner.jpg);

	h1 {
		//@media all and (min-width: map-get($grid-breakpoints, 'md')) { margin: 2% 0; }
	}

	p {
		@media all and (min-width: map-get($grid-breakpoints, 'md')) { font-size: 18px; }
	}
}

.page-top-quienes-somos h1,
.top-marcas h1 {
	//
	&::after {
		border-top: 4px solid $brand-primary;
		content: '';
		display: block;
		margin: 3rem auto 0;
		width: 17rem;
	}
}

.founder-name {
	background-color: $black;
	color: $white;
	padding: 1rem;

	span {
		color: $brand-primary;
		display: block;
	}
}

.sec-founders {
	.cat { margin: 1rem 0 0; }
	.cel-image img { vertical-align: bottom; }
	.ico::before { font-size: 2rem; }
}

blockquote {
	border-left: 2px solid $brand-primary;
	margin: 1rem 0;
	padding-left: 2rem;
}

.sec-quienes-somos .quienes-somos-comunidad,
.sec-quienes-somos .quienes-somos-capital,
.sec-quienes-somos .quienes-somos-incubadora {
	//
	&::after { content: none; }
}

.sec-quienes-somos .quienes-somos-comunidad { background-image: url(#{$_image-path}/bkg/quienes-somos-banner-1.jpg); }
.sec-quienes-somos .quienes-somos-capital { background-image: url(#{$_image-path}/bkg/quienes-somos-banner-2.jpg); }
.sec-quienes-somos .quienes-somos-incubadora { background-image: url(#{$_image-path}/bkg/quienes-somos-banner-3.jpg); }

@media (min-width: map-get($grid-breakpoints, 'md')) and (-webkit-min-device-pixel-ratio: 2), (min-width: map-get($grid-breakpoints, 'md')) and (min-resolution: 192dpi) {
	.sec-quienes-somos .quienes-somos-comunidad { background-image: url(#{$_image-path}/bkg/quienes-somos-banner-1@2x.jpg); }
	.sec-quienes-somos .quienes-somos-capital { background-image: url(#{$_image-path}/bkg/quienes-somos-banner-2@2x.jpg); }
	.sec-quienes-somos .quienes-somos-incubadora { background-image: url(#{$_image-path}/bkg/quienes-somos-banner-3@2x.jpg); }
}

//PAGE MISION Y VISION

.page-section-mision-vision {
	font-size: 1.4rem;

	h2 {
		margin-bottom: 2rem; margin-top: 2rem; text-align: left;
		&::after { content: none; }
		@media all and (min-width: map-get($grid-breakpoints, 'md')) { margin-bottom: 2rem; };

	}

	.heading-ganar {
		//
		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { margin-bottom: 2rem; };
	}

	.heading-valores h2 {
		@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { margin-top: 4rem; };
	}

}

// PAGE HISTORIA

.page-section-nuestra-historia {
	font-size: 1.4rem;
	margin: 4rem 0;
	position: relative;

	@media all and (min-width: map-get($grid-breakpoints, 'md')) {
		&::after { border-left: 2px solid $brand-primary; bottom: 0; content: ''; left: 49.9%; position: absolute; right: auto; top: 0; transform: translate(-50%); }
		&::before { background: $brand-primary; border-radius: 50%; content: ''; height: 15px; left: auto; position: absolute; right: 49.4%; top: -9px; width: 15px; }
	}

	h1 { color: $brand-primary; margin-top: 0; position: relative; }
	h2 { text-transform: capitalize; }

	.text-left h1,
	.text-left h2,
	.text-left p { text-align: left; }

	.text-right h1,
	.text-right h2,
	.text-right p { text-align: right; }

	h1::after { margin: 0 1px; position: absolute; }
	h2::after { content: none; }

	.text-left h1::after { left: 0; }
	.text-right h1::after { right: 0; }

	.text-left h1::before { left: -13px; }
	.text-right h1::before { right: -11px; }

	.row { padding: 2rem 0; }

	@media all and (min-width: map-get($grid-breakpoints, 'md')) {
		h1::before { background: $brand-primary; border-radius: 4px; bottom: -5px; content: ''; height: 8px; position: absolute; width: 8px; }
	}

	@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
		.odd .text-left { order: 1; text-align: right; }
		.odd .text-right { order: 2; text-align: left; }
	}
}

// PAGE ALIANZAS

.paragraph { margin-bottom: 2rem; }
.alianzas-logos {
	.cel-image { margin-bottom: 2rem; margin-top: 2rem; text-align: center; }
}

//PAGE NUESTRAS MARCAS

.top-marcas {
	background-color: $brand-secondary;
	//background-image: url(#{$_image-path}/bkg/nuestras-marcas-top-banner.png);
	background-repeat: no-repeat;
	background-size: contain;
	padding: 4rem 0;
	text-align: center;

	@media (min-width: map-get($grid-breakpoints, 'md')) and (-webkit-min-device-pixel-ratio: 2), (min-width: map-get($grid-breakpoints, 'md')) and (min-resolution: 192dpi) {
		//background-image: url(#{$_image-path}/bkg/nuestras-marcas-top-banner@2x.png);
	}

	@media all and (min-width: map-get($grid-breakpoints, 'md')) {
		min-height: calc(100vh -9rem);
		//padding-top: 9rem;
	}

	h1 {
		margin: 3rem 0 6rem;
		@media all and (min-width: map-get($grid-breakpoints, 'md')) {
			margin: 0;
			padding: 12rem 0;
		}
	}

	h2 { margin-bottom: 4rem; text-transform: uppercase; }

	.subtitle {
		@media all and (min-width: map-get($grid-breakpoints, 'md')) {
			margin-top: 7%;
		}
	}
}


.sec-marcas {
	//
	.cel-image {
		padding-bottom: 6rem;
		text-align: center;

		p { text-align: center; }

		.button {
			bottom: 0;
			display: block;
			left: 0;
			margin: 0 auto;
			max-width: 125px;
			position: absolute;
			right: 0;
		}
	}
}

.usp {
	background: top center no-repeat; background-size: 5.8rem 5.8rem; padding-top: 11.2rem;
	@media all and (min-width: map-get($grid-breakpoints, 'md')) { background-size: 8.8rem 8.8rem; }
}

.usp-1 { background-image: url(#{$_image-path}/icons/marcas-bulb.svg); }
.usp-2 { background-image: url(#{$_image-path}/icons/marcas-waitt-icon.svg); }
.usp-3 { background-image: url(#{$_image-path}/icons/marcas-realstate-icon.png); }

.cat {
	margin: 2rem 0;
	@media all and (min-width: map-get($grid-breakpoints, 'md')) {
		margin: 5rem 0;
	}
}


// PAGE CONTACTO

.direcciones h3 {
	background: url(#{$_image-path}/icons/pointeerest.svg) center left no-repeat;
	padding: 1rem 3.5rem;
	text-align: left;
}


//MAPS
// **************************************************

.google-maps {
	//
	.maps-container {
		height: 75vh;
		width: 100%;
	}

	.direcciones {
		margin: 4rem 0 0;
		a { color: $brand-primary; }
	}
}


//COUNTER

.counter-container {
	padding: 0;
	h3 { font-family: 'Lato'; font-size: 1.5rem; }
	@media all and (min-width: map-get($grid-breakpoints, 'sm')) { padding: 4rem 0; }
}

.counter {
	border-bottom: 1px solid $brand-accent;
	color: $brand-darker;
	font-family: $headings-font-family;
	font-size: 7rem;
	margin: 0 3rem;
	@media all and (min-width: map-get($grid-breakpoints, 'sm')) { font-size: 9rem; }
}


// Garantia

.page-section-howto .cel-image {
	margin-top: 4rem;
}

.title-movilevolution { background-image: url(#{$_image-path}/logo-movil-evolution.svg); }
.title-lastcode { background-image: url(#{$_image-path}/logo-lastcode.svg); }




.garantia {
	background: $white url(#{$_image-path}/icons/garantia-express-ico.svg) no-repeat left center;
	color: $gray;
	font-weight: 800;
	padding: 2rem 4rem;
	padding-left: 6rem;

	&:hover { background: $white; color: $gray; }
}

//SECTION HOME News
@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) {
	.page-recomendations { padding-bottom: 0;}
}

//Gracias

.pg-gracias {
	text-align: center;
	.button { margin-bottom: 2rem; margin-top: 4rem; }
}



// BLOG
// **************************************************

.blog { padding-top: 5rem; }

.balazo {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	margin-bottom: 1.6rem;
	overflow: hidden;
}

// ENTRY
// **************************************************

.entry-content {
	max-width: 768px;
	padding-bottom: 3.2rem;
	padding-top: 0;
	p { color: $brand-accent; font-family: 'Lato'; }
	//@media all and (min-width: map-get($grid-breakpoints, $_header-mobile-breakpoint)) { padding-top: $_header-content-offset-xl; }
}

.entry-node {
	margin-bottom: 4rem;
	text-align: left;
	
	@media all and (max-width: map-get($grid-breakpoints, 'sm') - 1) { margin: 2rem auto; max-width: 40rem; }

	a { text-decoration: none; }
	img { display: block; margin: 0 auto; width: 100%; }

	.title {
		font-family: $headings-font-family; font-size: 1.6rem; height: auto; line-height: 2rem; overflow: hidden; padding: 1.6rem 0; text-transform: uppercase;
	}
}

.entry-images > div {
	background: $white;

	//@media all and (min-width: map-get($grid-breakpoints, 'lg')) { order: 2; }

	.swiper-container { margin: 0 auto; max-width: 47.2rem; }
}

.entry-info {
	//
	@media all and (min-width: map-get($grid-breakpoints, 'lg')) { order: 3; }

	h1 {
		font-size: $font-size-h2;
		&::first-line { color: $brand-darker; }
	}

	h2,
	h2::first-line { color: $brand-primary; font-size: 2.4rem; }
}

.entry-menu {
	//
	@media all and (max-width: map-get($grid-breakpoints, 'lg') - 1) { display: none; }
	@media all and (min-width: map-get($grid-breakpoints, 'lg')) { order: 1; }
}

.entry-image {
	max-height: 48.8rem; max-width: 47.2rem; min-height: 32rem;
}

.entry-image-thumbs {
	//
	a {
		background: $gray-lightest; display: inline-block; line-height: 1; margin-right: 1.6rem; transition: $transition-base;

		&:hover,
		&.active { background: $brand-primary; }
	}

	img { display: block; opacity: 0.5; width: 9.4rem; }
}

.entry-description { padding-bottom: 3.2rem; }
.entry-add { margin-bottom: 4.8rem; }

.entry-title {
	h1 {
		@media all and (min-width: map-get($grid-breakpoints, 'md')) { font-size: 4.5rem; line-height: 1.3; }
	}

	h2 { line-height: 1.5; }
}

.featured-img {
	@media all and (max-width: map-get($grid-breakpoints, 'md') - 1) { text-align: center; }
}

// ARTICLE

.article .entry-content { padding-bottom: 0; }

.entry-title,
.article-title { padding: 4rem 0;
	@media all and (min-width: map-get($grid-breakpoints, 'md')) {
		padding: 8rem 0 4rem;
	}
}

.article-content h3 {
	color: $brand-darker;
	font-family: 'Lato';
	margin-top: 2rem;

	@media all and (min-width: map-get($grid-breakpoints, 'md')) {
		margin-top: 4rem;
	}
}

// DEFAULT PAGES
// **************************************************

.default {

	h1,
	h2 {
		color: $black;
		margin-bottom: 4rem;
		text-align: center;
		text-transform: uppercase;

		&::after {
			border-top: 2px solid $brand-primary;
			content: '';
			display: block;
			margin: 10px auto 0;
			width: 7rem;
			@media all and (min-width: map-get($grid-breakpoints, 'md')) { width: 17rem; }
		}

	}

	.content .container-fluid { max-width: 64rem; padding-bottom: 8rem; padding-top: 8rem; }

}
