/**
 * Swiper 3.4.2
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2017, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: March 10, 2017
 */


$_swiper-is-vertical   : false !default;
$_swiper-enable-arrows : true !default;
$_swiper-enable-nav    : true !default;
$_swiper-nav-border    : solid 1px transparentize($black, 0.5) !default;
$_swiper-nav-bg        : transparentize($black, 0.7) !default;
$_swiper-nav-active    : $brand-accent !default;
$_swiper-nav-size      : 1rem !default;
$_swiper-cover-banner  : true !default;




.swiper-container { margin-left: auto; margin-right: auto; position: relative; overflow: hidden; z-index: 1; }
.swiper-container-no-flexbox .swiper-slide { float: left; }


@if $_swiper-is-vertical
{
	.swiper-container-vertical > .swiper-wrapper { box-orient: vertical; flex-direction: column; }
}


.swiper-wrapper { box-sizing: content-box; display: flex; height: 100%; position: relative; transition-property: transform; width: 100%; z-index: 1; }
.swiper-container-android .swiper-slide,
.swiper-wrapper { transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper { box-lines: multiple; flex-wrap: wrap; }
.swiper-container-free-mode > .swiper-wrapper { margin: 0 auto; transition-timing-function: ease-out; }
.swiper-slide {
	height: 100%; flex-shrink: 0; position: relative; width: 100%;

	.container { position: relative; z-index: 3; }
	.banner { position: absolute; z-index: 2; }
}

// /* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide { height: auto; }
.swiper-container-autoheight .swiper-wrapper { align-items: flex-start; transition-property: transform, height; }




// navegation arrows
@if $_swiper-enable-arrows
{
	.swiper-button-prev,
	.swiper-button-next { cursor: pointer; position: absolute; top: 50%; transform: translateY(-50%); z-index: 10; }
	.swiper-button-prev.swiper-button-disabled,
	.swiper-button-next.swiper-button-disabled { cursor: default; opacity: 0.35; pointer-events: none; }

	.swiper-button-prev,
	.swiper-container-rtl .swiper-button-next { left: 0; right: auto; }

	.swiper-button-next,
	.swiper-container-rtl .swiper-button-prev { left: auto; right: 0; }

	@if $_swiper-is-vertical
	{
		.swiper-button-prev,
		.swiper-button-next { left: 50%; right: auto; transform: translateX(-50%); }

		.swiper-button-prev { bottom: auto; top: 3.2rem; }
		.swiper-button-next { bottom: 3.2rem; top: auto; }
	}
}




// navegation
@if $_swiper-enable-nav
{
	.swiper-pagination { bottom: 0; left: 50%; position: absolute; text-align: center; transition: 300ms; transform: translateX(-50%); z-index: 10; }

	.swiper-pagination-bullet { background: $_swiper-nav-bg; background-clip: padding-box; border: $_swiper-nav-border; border-radius: 100%; display: inline-block; height: $_swiper-nav-size; line-height: 1; margin: 1.1rem 0.7rem; width: $_swiper-nav-size; }
	.swiper-pagination-clickable .swiper-pagination-bullet { cursor: pointer; }
	.swiper-pagination-bullet-active { background: $_swiper-nav-active; background-clip: padding-box; }


	@if $_swiper-is-vertical
	{
		.swiper-container-vertical > .swiper-pagination { bottom: auto; left: auto; right: 3.2rem; top: 50%; transition: translateY(-50%); }
		.swiper-container-vertical > .swiper-pagination-bullets { right: 1rem; top: 50%; transform: translate3d(0px, -50%, 0); }
		.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet { display: block; }
	}
}




// use banners as backgrounds
@if $_swiper-cover-banner
{
	.swiper-slide {
		overflow: hidden;
		.banner { height: auto; left: 50%; max-width: none; min-height: 100%; min-width: 100%; top: 50%; transform: translate(-50%, -50%); width: auto; }
	}
}
