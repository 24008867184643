/*
 * SCSS BASIC: BLOCK Tags file
 * by SalsaLab
 */




// Common for all text based elements
// **************************************************

%bodytext-block { margin: $_block-margins; }


p { @extend %bodytext-block; }

h1,
h2,
h3,
h4,
h5,
h6 { font-family: $headings-font-family; line-height: $line-height-lg; margin: $_block-margins; }

h1 { font-size: $font-size-h1; }
h2 { font-size: $font-size-h2; }
h3 { font-size: $font-size-h3; }
h4 { font-size: $font-size-h4; }
h5 { font-size: $font-size-h5; }
h6 { font-size: $font-size-h6; }




// Embed Elements
// **************************************************

img,
svg { display: inline-block; height: auto; max-width: 100%; vertical-align: baseline; }

img,
iframe { border: 0; }

svg:not(:root) { overflow: hidden; }
audio:not([controls]) { display: none; height: 0; }

// video {}
// embed {}
// canvas {}
// object {}




// Ordered and Unordered Lists
// **************************************************

@if $_enable-lists {

	// vars
	$_lists-indent       : 2em !default;
	$_lists-bullet       : '\2022' !default;
	$_lists-counter      : counter(bullet) "." !default;
	$_lists-glyph-color  : $brand-primary !default;




	ul,
	ol { @extend %bodytext-block; padding-left: $_lists-indent; }

	li {
		list-style: none; position: relative;
		&::before { color: $_lists-glyph-color; left: $_lists-indent * -1; position: absolute; top: 0; }
	}

	ol { counter-reset: bullet; }
	ol > li::before { content: $_lists-counter; counter-increment: bullet; }
	ul > li::before { content: $_lists-bullet }

	// list inside nave elements are not body text lists
	nav {
		ul,
		ol { margin: 0; padding: 0; }

		li::before { content: none; }
	}
}




// Definition Lists
// **************************************************

@if $_enable-definition-lists {

	// vars
	$_deflists-indent    : 2em !default;
	$_deflists-dt-color  : $brand-primary !default;




	dl { @extend %bodytext-block; }
	dt { color: $_deflists-dt-color; }
	dd {
		margin: $_block-margins;

		&:first-child { margin-top: 0; }
		&:last-child { margin-bottom: 0; }
	}
}




// Definition Lists
// **************************************************

@if $_enable-figure {
	figure { @extend %bodytext-block; }
	figcaption {}
}
