/**
 * SCSS Basic File
 * by SalsaLab
 */



// Iconfont
// **************************************************

@if $_enable-iconfont {

	@font-face {
		font-family: $_font-iconset;
		src: url("#{$_font-path}/iconfont.eot");
		src: url("#{$_font-path}/iconfont.eot?#iefix") format("embedded-opentype"),
			 url("#{$_font-path}/iconfont.woff2") format("woff2"),
			 url("#{$_font-path}/iconfont.woff") format("woff"),
			 url("#{$_font-path}/iconfont.ttf") format("truetype");
		font-weight: normal;
		font-style: normal;
	}
}




// TOP LEVEL Tags
// **************************************************

// Not using autoprefixer, and this ones are not on bourbon
// scss-lint:disable VendorPrefix
@at-root { @-ms-viewport { width: device-width; } }

html { font-size: 62.5%; line-height: $line-height-base; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }
body {
	background: $body-bg; box-sizing: border-box; color: $body-color; font-family: $font-family-base; font-size: 1em; -ms-overflow-style: scrollbar; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; margin: 0;

	> * { font-size: $font-size-base; }
}

*,
*::before,
*::after { box-sizing: inherit; }

.container-fluid { margin: 0 auto; max-width: map-get($container-max-widths, 'xl'); }
// scss-lint:enable VendorPrefix




@if $_enable-pre-debug
{
	.xdebug-var-dump { white-space: pre-wrap; width: 100%; }
}




// Basic imports

@import 'basic/block';
@import 'basic/inline';
@import 'basic/forms';
// @import 'basic/tables';


// Pattern imports
@import 'patterns/iconfont'
