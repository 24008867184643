/*
 * SCSS BASIC: FORMS Tags file
 * by SalsaLab
 */




// Form containers
// **************************************************

form {}


@if $_enable-fieldsets {

	fieldset { border: 0; margin: 0; padding: 0; }
	legend {}
}




// Material Like forms
// **************************************************

@if $_form-style == 'material-like' {

	// vars
	$_form-input-font-size    : 1.6rem !default;
	$_form-line-height        : 2.6rem !default;

	$_form-color              : $body-color !default;
	$_form-color-placeholder  : $gray-light !default;
	$_form-color-option       : $_form-color !default;
	$_form-color-disabled     : $gray-light !default;

	$_form-label-scale        : 0.75 !default;

	$_form-label-color        : $body-color !default;
	$_form-label-color-empty  : $body-color !default;
	$_form-label-color-focus  : $brand-primary !default;

	$_form-vertical-padding   : 2rem !default;
	$_form-input-v-padding    : 0.1rem !default;
	$_form-input-h-padding    : 0 !default;
	$_form-input-padding      : $_form-input-v-padding $_form-input-h-padding !default;

	$_form-line               : $gray-lightest !default;
	$_form-line-empty         : $_form-line !default;
	$_form-line-focus         : $brand-primary !default;
	$_form-line-disabled      : $gray-light !default;





	// structure classes
	.form-field { position: relative; }
	.form-content,
	.buttons { padding: $_form-vertical-padding 0; position: relative; }


	// helpers
	label {
		display: inline-block; font: inherit;
		&:empty { display: none; }
	}


	// extends
	%_empty-label { color: $_form-label-color-empty; font-size: $_form-input-font-size; line-height: $_form-input-font-size; padding: $_form-input-padding; pointer-events: none; top: $_form-vertical-padding; }


	// input, textarea & select
	select,
	textarea,
	input:not([type="radio"]):not([type="checkbox"]) {
		background: none; border: none; border-bottom: solid 1px $_form-line; color: $_form-color; font-family: inherit; font-size: $_form-input-font-size; height: $_form-line-height + ($_form-input-v-padding * 2); line-height: $_form-line-height; padding: $_form-input-padding; transition: $transition-base; width: 100%;
		+ label { color: $_form-label-color; display: block; font-size: $_form-input-font-size * $_form-label-scale; left: 0; position: absolute; top: $_form-vertical-padding - ($_form-vertical-padding * $_form-label-scale); transition: $transition-base; }

		// placeholder
		&::placeholder { color: $_form-color-placeholder; }

		// focus fields
		&:focus {
			border-bottom: solid 2px $_form-line-focus; box-shadow: none; padding-bottom: $_form-input-v-padding - 0.1rem;
			+ label { color: $_form-label-color-focus; }
		}

		// invalid inputs
		&:invalid,
		&.invalid { box-shadow: none; }

		// disabled
		&[disabled],
		&[disabled]:placeholder-shown:not(:focus) {
			border-color: $_form-line-disabled; color: $_form-color-disabled; cursor: default; pointer-events: none;

			+ label { color: $_form-color-disabled; }
		}
	}

	textarea,
	input:not([type="radio"]):not([type="checkbox"]) {
		&:placeholder-shown:not(:focus) {
			&::placeholder { color: $_form-label-color-empty; border-bottom-color: $_form-line-empty; opacity: 0; }
			+ label { @extend %_empty-label; }
		}

		&:invalid:not(:focus):not(:placeholder-shown),
		&.invalid:not(:focus):not(:placeholder-shown) {
			border-bottom-color: $brand-danger; box-shadow: none; color: $brand-danger;
			+ label { color: $brand-danger; }
		}
	}

	input {
		&[type="radio"],
		&[type="checkbox"] {
			margin-right: 1.6rem;
			+ label { color: $_form-label-color-focus; }
		}
	}

	select:invalid:not(:focus),
	select.invalid:not(:focus) {
		color: transparentize($_form-color-placeholder, 1); border-bottom-color: $_form-line-empty;
		+ label { @extend %_empty-label; }
	}

	option {
		color: $_form-color-option;
		&[disabled] { color: tint($_form-color-option, 30%); }
	}

	textarea { height: 3em; min-height: 3em; resize: vertical; }
}




// Buttons
// **************************************************

$_button-bg                   : $brand-accent !default;
$_button-bg-hover             : $_button-bg !default;
$_button-bg-disabled          : $gray-lighter !default;

$_button-color                : $white !default;
$_button-color-hover          : $_button-color !default;
$_button-color-disabled       : $gray-light !default;

$_button-bg-primary           : $brand-primary !default;
$_button-bg-primary-hover     : $_button-bg-primary !default;
$_button-color-primary        : $_button-color !default;
$_button-color-primary-hover  : $_button-color-primary !default;

$_button-padding              : 0.8rem 1.6em !default;
$_button-effect               : 'scale' !default;
$_button-border-radius        : $border-radius;

$_enable-button-border-radius : true !default;
$_enable-button-negative      : false !default;
$_enable-button-primary       : false !default;




button,
.button {
	appereance: none; background: $_button-bg; border: 0; color: $_button-color; cursor: pointer; display: inline-block; font: inherit; line-height: 1; margin-bottom: $grid-gutter-width-base; padding: $_button-padding; text-align: center; text-decoration: none; transition: $transition-base;

	@if $_enable-button-border-radius {
		border-radius: $_button-border-radius;
	}

	+ button,
	+ .button { margin-left: $grid-gutter-width-base; }

	&:hover,
	&:focus,
	&:active { background: $_button-bg-hover; color: $_button-color-hover; text-decoration: none; }


	// negative
	@if $_enable-button-negative
	{
		&.negative { background: $_button-color; color: $_button-bg; }
	}


	// primary button
	@if $_enable-button-primary
	{
		&.primary {
			background: $_button-bg-primary; color: $_button-color-primary;

			&:hover,
			&:focus,
			&:active { background: $_button-bg-primary-hover; color: $_button-color-primary-hover; }

			@if $_enable-button-negative
			{
				&.negative { background: $_button-color-primary; color: $_button-bg-primary; }
			}
		}
	}


	// other button types here!!!




	// active effects
	@if $_button-effect == 'scale'
	{
		$_button-effect-hover-scale  : 1.05 !default;
		$_button-effect-active-scale : 0.9 !default;

		&:hover,
		&:focus { transform: scale($_button-effect-hover-scale, $_button-effect-hover-scale); }

		&:active { transform: scale($_button-effect-active-scale, $_button-effect-active-scale); transition: transform 0.1s ease-out; }
	}




	// disabled buttons
	&[disabled] { background: $_button-bg-disabled; color: $_button-color-disabled; cursor: default; pointer-events: none; }
}
